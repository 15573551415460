<template>
	<Slider :speed="5000" :autoplay="true">
		<div class="content" v-if="winter">
			<img class="headerimg" src="@/assets/images/hot-soup.jpg" alt="hot-soup" />
			<div class="slidefont">
				<p>Hot Soups-Served Fridays (Jan-April only)</p>
			</div>
		</div>
		<div class="content">
			<img class="headerimg" src="@/assets/images/fruits-vegetables.jpg" alt="fruits-vegetables" />
			<div class="slidefont">
				<p>We Sell Local, In-Season Fruits &amp; Vegetables</p>
			</div>
		</div>
		<div class="content">
			<img class="headerimg" src="@/assets/images/pretzels-hdr.jpg" alt="pretzels" />
			<div class="slidefont">
				<p>Stop in to try a hot, fresh pretzel!</p>
			</div>
		</div>
		<div class="content">
			<img class="headerimg" src="@/assets/images/shady-mountain-market.jpg" alt="Shady Mountain Market" />
		</div>
	</Slider>
	<section name="welcome">
		<div class="welcome-body">
			<h3 class="welcome-head">WELCOME TO SHADY MOUNTAIN MARKET</h3>
			<p>
				Would you like to sink your teeth into a soft, salty pretzel? Do you like to stock up on locally grown
				vegetables, or eat your sandwiches on freshly baked bread? We invite you to Shady Mountain Market. Our
				newly built bakery is home to freshly baked goods and locally grown vegetables. Experience the charm
				Pennsylvania has to offer.
			</p>
		</div>
		<Hours class="hours" />
	</section>
	<div id="column_list">
		<div class="column_item">
			<img class="column_img" src="@/assets/images/baked-goods.jpg" alt="baked_goods" />
			<img class="column_img img_400" src="@/assets/images/baked-goods2.jpg" alt="baked-goods" />
			<div class="column_text">
				<p>Baked</p>
				<p>Goods</p>
			</div>
			<div class="column_button">
				<router-link to="/baked-goods">Go</router-link>
			</div>
		</div>
		<div class="column_item">
			<img class="column_img" src="@/assets/images/frozen-dinners.jpg" alt="frozen-dinners" />
			<img class="column_img img_400" src="@/assets/images/frozen-dinners2.jpg" alt="frozen-dinners" />
			<div class="column_text">
				<p>frozen</p>
				<p>Dinners</p>
			</div>
			<div class="column_button">
				<router-link to="/frozen-dinners">Go</router-link>
			</div>
		</div>
		<div class="column_item">
			<img class="column_img" src="@/assets/images/canned-goods.jpg" alt="canned-goods" />
			<img class="column_img img_400" src="@/assets/images/canned-goods2.jpg" alt="canned_goods" />
			<div class="column_text">
				<p>Canned</p>
				<p>Goods</p>
			</div>
			<div class="column_button">
				<router-link to="/canned-goods">Go</router-link>
			</div>
		</div>
		<div class="column_item">
			<img class="column_img" src="@/assets/images/made-to-order.jpg" alt="made-to-order" />
			<img class="column_img img_400" src="@/assets/images/food-basket.jpg" alt="food-basket" />
			<div class="column_text">
				<p>made to</p>
				<p>Order</p>
			</div>
		</div>
	</div>
	<div id="goods_content">
		<div class="goods_item">
			<img src="@/assets/images/frozen-dinners-3.jpg" alt="frozen_dinners" />
			<div class="goods_title">
				<p>FROZEN DINNERS</p>
				<p class="goods_txt">
					Need something quickly for dinner? Too busy to cook? Let us do the cooking for you! We make 16
					different kinds of frozen dinners in two different sizes. All our dinners are made from scratch at
					Shady Mountain Market! If you have a family gathering, we will make your dinners in larger pans.
				</p>
			</div>
		</div>
		<div class="goods_item">
			<div class="goods_title">
				<p>BAKED GOODS</p>
				<p class="goods_txt">
					For a delicious selection of homemade, baked goods, visit our bakery. Enjoy our freshly baked sticky
					buns, cookies, pies, and cinnamon and raspberry rolls. We also have many kinds of whoopie pies,
					cakes, cupcakes and more.
				</p>
			</div>
			<img src="@/assets/images/baked-goods-3.jpg" alt="baked_goods" />
		</div>
		<div class="goods_item">
			<img src="@/assets/images/canned-goods-3.jpg" alt="canned_goods" />
			<div class="goods_title">
				<p>CANNED GOODS</p>
				<p class="goods_txt">
					If you're hungry for home canned goods, visit our store. We have a huge selection including jams and
					jellies, chow-chow-Red Beets, bread and butter pickles and much more.
				</p>
			</div>
		</div>
		<div class="goods_item">
			<div class="goods_title">
				<p>DELI</p>
				<p class="goods_txt">
					Visit our deli. We serve macaroni salad, potato salad, cranberry salad and more. Lunchmeats,
					cheeses, subs and wraps are now here!
				</p>
			</div>
			<img src="@/assets/images/lunch-meat-deli.jpg" alt="deli-meat" />
		</div>
	</div>
	<div id="made_to_order">
		<img class="column_img" src="@/assets/images/img_6(3).jpg" alt="made_to_order" />
		<img class="column_img" src="@/assets/images/bakedgoods.jpg" alt="made_to_order" />
		<div class="order_text">
			<p>made to</p>
			<p>Order</p>
		</div>
	</div>
	<div id="endtext">
		<p>
			Can't find what you're looking for? We make cakes for any occasion, decorated and personalized just for you.
			Having a wedding? We make rolls, cookies, angel food cakes or pies for you. Having a charity event? Whoopie
			pies and apple dumplings make great items for your fundraiser. Sugar free items are also available.
		</p>
	</div>
	<div id="deli">
		<img class="column_img" src="@/assets/images/lunch-meat-deli.jpg" alt="deli" />
		<div class="order_text">
			<p>Deli</p>
		</div>
	</div>
	<div id="deli-endtext">
		<p>
			Visit our deli. We serve macaroni salad, potato salad, cranberry salad and more. Lunchmeats, cheeses, subs
			and wraps are coming soon! <a href="tel:610-944-9580">Call</a> for availability.
		</p>
	</div>
	<div id="pretzels">
		<img class="column_img" src="@/assets/images/pretzels.jpg" alt="pretzels" />
		<img class="column_img" src="@/assets/images/pretzels_2.jpg" alt="pretzels" />
		<div class="order_text">
			<p>Ruthie's</p>
			<p>Pretzels</p>
		</div>
		<div>
			<p>
				Fresh pretzels are made daily! Stop by, and you can watch us roll hundreds of pretzels. Try our hard
				crunchy pretzels, or sample one of our soft, salty pretzels.
			</p>
		</div>
	</div>
</template>
<style scoped lang="scss">
.content > .headerimg {
	width: 100%;
	max-width: 100vw;
}

:deep().slider {
	&-controls {
		color: #000;
		opacity: 0.5;

		svg {
			margin: 0px 10px;
		}

		svg:hover {
			color: #411211;
		}
	}

	&-dots {
		position: relative;
		top: -25px;

		button {
			width: 10px;
			height: 12px;
			border-style: none;
		}

		& > * {
			background-color: gray;
			opacity: 0.5;
			&:hover {
				background-color: #c2b59c;
			}
		}

		& > .active {
			background-color: #411211;
			opacity: 1;
		}
	}
}

section[name="welcome"] {
	display: flex;
	flex-direction: column;
	margin: 0 auto;
	width: 70%;
	color: #5e0000;

	.hours {
		align-self: center;
		min-width: 120px;
		background-color: #c2b59c;
		padding: 20px;
		margin-left: 30px;
		color: #fff;
		font-family: "futura-pt", sans-serif;
	}

	@media (min-width: 850px) {
		padding: 20px 0;
	}

	@media (min-width: 600px) {
		flex-direction: row;
		width: 90%;
	}

	@media (min-width: 600px) {
		max-width: 800px;
	}

	.welcome-body {
		display: flex;
		flex-direction: column;
		text-align: justify;
		color: #000;
		font-family: "futura-pt", "Lato", sans-serif;
		.welcome-head {
			text-align: center;
			margin-top: 0;
			color: #5e0000;
			font-family: "bebas-neue", sans-serif;
		}

		@media (min-width: 750px) {
			p {
				padding-right: 30px;
			}
		}
	}
}

#endtext,
#deli-endtext {
	padding: 0 10px 10px 10px;
}
</style>

<script>
import Slider from "@/components/Slider.vue";
import Hours from "@/components/Hours.vue";

export default {
	components: {
		Hours,
		Slider
	},
	computed: {
		winter() {
			let m = new Date().getMonth();
			return m < 5; // Jan - Apr
		}
	}
};
</script>
