<script>
export default {
	props: {
		icon: {
			type: Boolean,
			default: false
		}
	},
	computed: {
		winter() {
			let m = new Date().getMonth();
			return m < 4; // Jan - Mar
		}
	}
};
</script>

<style scoped lang="scss">
.hours {
	display: flex;
	flex-direction: row;

	i {
		padding-right: 5px;
	}
}
</style>

<template>
	<div class="hours">
		<i v-if="icon" class="far fa-clock"></i>
		<div v-if="winter">
			<p class="hr-head">WINTER HOURS</p>
			<p>
				JAN-MAR<br />
				MON-SAT 8-5<br />
				SUNDAY CLOSED
			</p>
		</div>
		<div v-else>
			<p class="hr-head">HOURS</p>
			<p>
				MONDAY-FRIDAY 8-6<br />
				SATURDAY 8-5<br />
				SUNDAY CLOSED
			</p>
		</div>
	</div>
</template>
